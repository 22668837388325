import SubscriberService from '@/services/subscriber.service';
import Subscriber from '@/store/models/subscriber';

export default {
  namespaced: true,
  state: {
    formSubscriberId: null,
    filters: {},
    limit: 25,
    currentPage: 1,
    lastPage: 1,
    paginationOptions: [{ limit: 25 }, { limit: 50 }, { limit: 100 }],
  },
  mutations: {
    formSubscriberId(state, formSubscriberId) {
      state.formSubscriberId = formSubscriberId;
    },
    filters(state, filters) {
      state.filters = filters;
    },
    limit(state, limit) {
      state.limit = limit;
    },
    currentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    lastPage(state, lastPage) {
      state.lastPage = lastPage;
    },
  },
  actions: {
    init({ dispatch }) {
      return dispatch('getSubscribers');
    },
    terminate() {
      return Subscriber.deleteAll();
    },
    async newSubscriber({
      commit,
      getters,
    }) {
      const subscriber = getters.newSubscriber || await Subscriber.createNew();
      commit('formSubscriberId', subscriber.id);
    },
    async discardNewSubscriber({ getters }) {
      if (getters.newSubscriber) {
        getters.newSubscriber.$delete();
      }
    },
    subscriberProps(store, payload) {
      Subscriber.update({
        where: payload.subscriberId,
        data: payload.props,
      });
    },
    async getSubscribers({
      commit,
      rootState,
      state,
    }) {
      const res = await SubscriberService.getSubscribers(rootState.products.productId, {
        filters: state.filters,
        limit: state.limit,
        page: state.currentPage,
      });
      commit('lastPage', res.last_page);
      Subscriber.query()
        .where('id', (subscriber) => subscriber.id !== state.formSubscriberId)
        .delete();
      Subscriber.create({ data: res.data });
    },
    async getSubscriber({ rootState }, id) {
      const res = await SubscriberService.getSubscriber(rootState.products.productId, id);
      await Subscriber.insert({ data: res });
    },
    createSubscriber({ rootState }, subscriber) {
      return SubscriberService.createSubscriber(rootState.products.productId, subscriber);
    },
    updateSubscriber({ rootState }, subscriber) {
      return SubscriberService.updateSubscriber(rootState.products.productId, subscriber);
    },
  },
  getters: {
    all() {
      return Subscriber.query()
        .where('$isNew', false)
        .get();
    },
    formSubscriber(state) {
      if (!state.formSubscriberId) {
        return null;
      }
      return Subscriber.query()
        .find(state.formSubscriberId);
    },
    newSubscriber() {
      return Subscriber.query()
        .where('$isNew', true)
        .first();
    },
  },
};
