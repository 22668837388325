import AlertChannel from '@/store/models/alert-channel';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async alertChannelProps(store, payload) {
      return AlertChannel.update({
        where: payload.channelId,
        data: payload.props,
      });
    },
    async addNewAlertChannel(store, payload) {
      const channel = await AlertChannel.createNew();
      await channel.$update({ product_id: payload.productId });
    },
  },
};
