import AuthService from '@/services/auth.service';
import router from '@/router';

function isAuthRoute(route) {
  return route.name && ['login', 'signup', 'email-verification'].indexOf(route.name) !== -1;
}

export default {
  namespaced: true,
  state: {
    isAuthenticated: null,
    redirectToRoute: null,
  },
  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    redirectToRoute(state, redirectToRoute) {
      state.redirectToRoute = redirectToRoute;
    },
  },
  actions: {
    init({ commit }) {
      commit('isAuthenticated', AuthService.isAuthenticated());
    },
    login(store, user) {
      return AuthService.login(user);
    },
    signup(store, user) {
      return AuthService.register(user);
    },
    authSuccess({ commit, state }, payload) {
      commit('isAuthenticated', payload.isAuthenticated);
      if (state.redirectToRoute) {
        router.push(state.redirectToRoute);
      } else {
        router.push({ name: 'alerts' });
      }
    },
    logout({ commit }) {
      commit('isAuthenticated', false);

      if (!isAuthRoute(router.currentRoute)) {
        AuthService.logout();
        router.push({ name: 'login' });
      }
    },
    loggedInGuard({ state, commit }, to) {
      if (!state.isAuthenticated) {
        if (!isAuthRoute(to)) {
          commit('redirectToRoute', to.fullPath);
        }
        router.push({ name: 'login' });
        return false;
      }
      return true;
    },
    loggedOutGuard({ state }) {
      if (state.isAuthenticated) {
        router.push({ name: 'dashboard' });
        return false;
      }
      return true;
    },
    unauthorized({ commit, dispatch }) {
      if (!isAuthRoute(router.currentRoute)) {
        commit('redirectToRoute', router.currentRoute.name);
      }
      dispatch('logout');
    },
  },
};
