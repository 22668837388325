import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';
import dayjs from 'dayjs';

export default class Page extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'pages';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      title: this.attr(''),
      content: this.attr(''),
      forbidden_message: this.attr(''),
      service_id: this.attr(''),
      product_id: this.attr(null),
      updated_at: this.attr(''),
      created_at: this.attr(dayjs().format()),
    };
  }
}
