export class PositionStatus {
  static OPEN = 'OPEN';

  static CLOSED = 'CLOSED';

  static labels = {
    [this.OPEN]: 'Open',
    [this.CLOSED]: 'Closed',
  };

  static routeMap = {
    open: this.OPEN,
    closed: this.CLOSED,
  }

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
