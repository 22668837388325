export class PositionSide {
  static BUY = 'BUY';

  static SELL = 'SELL';

  static labels = {
    [this.BUY]: 'Buy',
    [this.SELL]: 'Short',
  };

  static classes = {
    [this.BUY]: 'success',
    [this.SELL]: 'danger',
  };

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
      class: this.classes[key],
    }));
}
