export class AlertStatus {
  static DRAFT = 'DRAFT';

  static CONFIRMED = 'CONFIRMED';

  static DISCARDED = 'DISCARDED';

  static labels = {
    [this.DRAFT]: 'Draft',
    [this.CONFIRMED]: 'Confirmed',
    [this.DISCARDED]: 'Discarded',
  };

  static routeMap = {
    drafts: this.DRAFT,
    sent: this.CONFIRMED,
    discarded: this.DISCARDED,
  }

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
