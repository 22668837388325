export default {
  namespaced: true,
  state: {
    isOpen: false,
    isCollapsed: false,
  },
  mutations: {
    isOpen(state, payload) {
      state.isOpen = payload;
    },
    isCollapsed(state, payload) {
      state.isCollapsed = payload;
    },
  },
};
