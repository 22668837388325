import ProductService from '@/services/product.service';
import Product from '@/store/models/product';

export default {
  namespaced: true,
  state: {
    productId: null,
    serviceId: null,
  },
  mutations: {
    productId(state, productId) {
      state.productId = productId;
    },
    serviceId(state, serviceId) {
      state.serviceId = serviceId;
    },
  },
  actions: {
    async init({
      dispatch,
      commit,
    }, productId) {
      commit('productId', null);

      await dispatch('subscribers/terminate', null, { root: true });
      await dispatch('alerts/terminate', null, { root: true });
      await dispatch('liveAlerts/terminate', null, { root: true });
      await dispatch('positions/terminate', null, { root: true });
      await dispatch('tickers/terminate', null, { root: true });
      await dispatch('pages/terminate', null, { root: true });
      commit('serviceId', null);

      await dispatch('getProducts');

      productId = await dispatch('resolveActiveProduct', productId);

      if (productId) {
        await dispatch('updateServiceId');
        await dispatch('subscribers/init', null, { root: true });
        await dispatch('alerts/init', null, { root: true });
        await dispatch('liveAlerts/init', null, { root: true });
        await dispatch('positions/init', null, { root: true });
        await dispatch('tickers/init', null, { root: true });
        await dispatch('pages/init', null, { root: true });
      }
      return productId;
    },
    async resolveActiveProduct({
      commit,
      getters,
    }, productId) {
      if (!productId) {
        const lastId = localStorage.getItem('productId');
        const productIds = getters.all.map((product) => product.id);
        // check if last active product is in products list
        if (productIds.includes(lastId)) {
          productId = lastId;
        }
      }

      if (!productId && getters.all.length > 0) {
        productId = getters.all[0].id;
      }

      localStorage.setItem('productId', productId);

      commit('productId', productId);

      return productId;
    },
    async getProducts({ dispatch }) {
      const products = await ProductService.getMyProducts();
      await Product.create({ data: products });
      await dispatch('updateServiceId');
      return products;
    },
    async productProps(store, payload) {
      return Product.update({
        where: payload.productId,
        data: payload.props,
      });
    },
    updateServiceId({
      state,
      getters,
      commit,
    }) {
      const product = getters.product;
      if (!state.serviceId && product && product.services && Object.keys(product.services).length) {
        commit('serviceId', Object.keys(product.services)[0]);
      }
    },
    async updateProduct({ getters }) {
      return ProductService.updateProduct(getters.product);
    },
  },
  getters: {
    product(state) {
      return Product.query()
        .with(['alert_channels'])
        .find(state.productId);
    },
    all() {
      return Product.query()
        .where('$isNew', false)
        .get();
    },
  },
};
