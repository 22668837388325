import AlertService from '@/services/alert.service';
import TickerService from '@/services/ticker.service';
import Alert from '@/store/models/alert';
import { AlertStatus } from '@/store/models/alert-status';
import { AlertType } from '@/store/models/alert-type';
import { AlertSide } from '@/store/models/alert-side';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    formAlertId: null,
    status: AlertStatus.DRAFT,
    limit: 15,
    offset: 0,
    allLoaded: false,
    isLoading: false,
  },
  mutations: {
    formAlertId(state, formAlertId) {
      state.formAlertId = formAlertId;
    },
    status(state, status) {
      state.status = status;
    },
    limit(state, limit) {
      state.limit = limit;
    },
    offset(state, offset) {
      state.offset = offset;
    },
    allLoaded(state, allLoaded) {
      state.allLoaded = allLoaded;
    },
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    init({ dispatch }) {
      return dispatch('getAlerts');
    },
    terminate() {
      return Alert.deleteAll();
    },
    async newAlert({
      commit,
      getters,
    }) {
      const alert = getters.newAlert || await Alert.createNew();
      commit('formAlertId', alert.id);
      return alert;
    },
    async newClosePositionAlert({
      dispatch,
      state,
    }, position) {
      await router.push({
        name: 'alert',
        params: {
          section: 'drafts',
          id: 'new',
        },
      });

      const keyword = position.from_ticker + position.to_ticker;
      const ticker = await TickerService.getTickers(position.product_id, { keyword });

      await dispatch('alertProps', {
        alertId: state.formAlertId,
        props: {
          type: AlertType.MARKET,
          side: position.side === AlertSide.BUY ? AlertSide.SELL : AlertSide.BUY, // If position is short, buy; If position is long, sell.
          from_ticker: position.from_ticker,
          to_ticker: position.to_ticker,
          price: Object.values(ticker)[0].price,
          portfolio_percentage: position.portfolio_percentage,
          service_id: position.service_id,
        },
      });
    },
    async discardNewAlert({ getters }) {
      if (getters.newAlert) {
        getters.newAlert.$delete();
      }
    },
    alertProps(store, payload) {
      Alert.update({
        where: payload.alertId,
        data: payload.props,
      });
    },
    async getAlerts({
      rootState,
      state,
      commit,
    }) {
      commit('isLoading', true);

      const alerts = await AlertService.getAlerts(rootState.products.productId, {
        limit: state.limit,
        offset: state.offset,
        service_id: rootState.products.serviceId,
      });

      Alert.query()
        .where('id', (alert) => alert.id !== state.formAlertId)
        .delete();
      Alert.insert({ data: alerts });

      commit('isLoading', false);
      commit('offset', state.offset + state.limit);

      if (alerts.length < state.limit) {
        commit('allLoaded', true);
      }
    },
    async getAlert({ rootState }, id) {
      const res = await AlertService.getAlert(rootState.products.productId, id);
      await Alert.insert({ data: res });
    },
    createAlert({ rootState }, alert) {
      return AlertService.createAlert(rootState.products.productId, alert);
    },
    updateAlert({ rootState }, alert) {
      return AlertService.updateAlert(rootState.products.productId, alert);
    },
    sendAlert({ rootState }, alert) {
      return AlertService.confirmAlert(rootState.products.productId, alert);
    },
    discardAlert({ rootState }, alert) {
      return AlertService.discardAlert(rootState.products.productId, alert);
    },
    async resetAlerts({
      dispatch,
      commit,
    }) {
      await dispatch('terminate');
      commit('limit', 15);
      commit('offset', 0);
      commit('allLoaded', false);
      dispatch('getAlerts');
    },
  },
  getters: {
    all(state) {
      return Alert.query()
        .with('channel_statuses')
        .orderBy('created_at', 'desc')
        .where('status', state.status)
        .get();
    },
    formAlert(state) {
      if (!state.formAlertId) {
        return null;
      }

      return Alert.query()
        .find(state.formAlertId);
    },
    newAlert() {
      return Alert.query()
        .where('$isNew', true)
        .first();
    },
    alertDraftsCount() {
      return Alert.query()
        .where('status', 'DRAFT')
        .get().length;
    },
  },
};
