<template>
    <div id="app" class="d-flex h-100 container-fluid px-0">
        <vue-progress-bar/>
        <transition name="fade" mode="out-in">
            <router-view class="d-flex flex-row w-100"/>
        </transition>
        <notifications position="bottom center" classes="snackbar" width="332"/>
    </div>
</template>

<script>

export default {
  components: {},
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import 'bootstrap';
@import '../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css';
@import '@/assets/scss/app';
</style>
