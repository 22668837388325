export class AlertType {
  static MARKET = 'MARKET';

  static LIMIT = 'LIMIT';

  static POST = 'POST';

  static labels = {
    [this.MARKET]: 'Market',
    [this.LIMIT]: 'Limit',
    [this.POST]: 'Post',
  };

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
