import HttpService from '@/services/http.service';

class ProductService {
  getMyProducts() {
    return HttpService.get('products/me')
      .then((res) => res.data);
  }

  getProduct(productId) {
    return HttpService.get(`products/${productId}`)
      .then((res) => res.data);
  }

  updateProduct(product) {
    return HttpService.patch(`products/${product.id}`, product)
      .then((res) => res.data);
  }

  getExchangeConnection(productId) {
    return HttpService.get(`products/${productId}/exchange_connection`)
      .then((res) => res.data);
  }
}

export default new ProductService();
