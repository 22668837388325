import PositionService from '@/services/position.service';
import Position from '@/store/models/position';
import { PositionStatus } from '@/store/models/position-status';

export default {
  namespaced: true,
  state: {
    positionId: null,
    status: PositionStatus.OPEN,
  },
  mutations: {
    positionId(state, positionId) {
      state.positionId = positionId;
    },
    status(state, status) {
      state.status = status;
    },
  },
  actions: {
    init({ dispatch }) {
      return dispatch('getPositions');
    },
    terminate() {
      return Position.deleteAll();
    },
    async newPosition({ commit, getters }) {
      const position = getters.newPosition || await Position.createNew();
      commit('positionId', position.id);
    },
    async discardNewPosition({ getters }) {
      if (getters.newPosition) {
        getters.newPosition.$delete();
      }
    },
    positionProps(store, payload) {
      Position.update({
        where: payload.positionId,
        data: payload.props,
      });
    },
    async getPositions({ rootState, state }/* , query */) {
      const res = await PositionService.getPositions(rootState.products.productId, {
        service_id: rootState.products.serviceId,
      });
      // const res = await PositionService.getPositions(query);
      Position.query()
        .where('id', (position) => position.id !== state.positionId)
        .delete();
      Position.insert({ data: res });
    },
    async getPosition({ rootState, commit }, id) {
      const res = await PositionService.getPosition(rootState.products.productId, id);
      await Position.insert({ data: res });
      commit('positionId', res.id);
    },
    createPosition({ rootState, getters }) {
      return PositionService.createPosition(rootState.products.productId, getters.position);
    },
    updatePosition({ rootState, getters }) {
      return PositionService.updatePosition(rootState.products.productId, getters.position);
    },
  },
  getters: {
    all(state) {
      return Position.query()
        .orderBy('created_at', 'desc')
        .where('status', state.status)
        .get();
    },
    position(state) {
      if (!state.positionId) {
        return null;
      }
      return Position.query()
        .find(state.positionId);
    },
    newPosition() {
      return Position.query()
        .where('$isNew', true)
        .first();
    },
  },
};
