import UserService from '@/services/user.service';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    userProp(state, payload) {
      state.user = { ...state.user, ...payload };
    },
  },
  actions: {
    init({ dispatch }) {
      return dispatch('getUser');
    },
    async getUser({ commit }) {
      const user = await UserService.getMe();
      commit('user', user);
    },
    async updateProps({ state, dispatch }) {
      const res = await UserService.updateMe(state.user);
      dispatch('getUser');
      return res;
    },
  },
};
