import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';
import { AlertType } from '@/store/models/alert-type';
import { AlertSide } from '@/store/models/alert-side';
import { AlertStatus } from '@/store/models/alert-status';
import dayjs from 'dayjs';
import AlertChannelStatus from '@/store/models/alert-channel-status';

export default class Alert extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'alerts';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      side: this.attr(AlertSide.BUY),
      type: this.attr(AlertType.MARKET),
      status: this.attr(AlertStatus.DRAFT),
      from_ticker: this.attr(''),
      to_ticker: this.attr(''),
      price: this.attr(''),
      position_average_price: this.attr(null),
      position_percentage: this.attr(null),
      portfolio_percentage: this.attr(''),
      position_id: this.attr(null),
      product_id: this.attr(null),
      service_id: this.attr(null),
      title: this.attr(null),
      content: this.attr(null),
      comment: this.attr(null),
      stop: this.attr(null),
      target: this.attr(null),
      executed_at: this.attr(''),
      expires_at: this.attr(''),
      channel_statuses: this.hasMany(AlertChannelStatus, 'alert_id'),
      creator_name: this.attr(''),
      updated_at: this.attr(''),
      created_at: this.attr(dayjs().format()),
    };
  }
}
