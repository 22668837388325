import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import './registerServiceWorker';
import progressbarConfig from '@/config/progressbar.config';
import { ModalPlugin } from 'bootstrap-vue';
import router from './router';
import store from './store/store';
import App from './App.vue';

Vue.use(ModalPlugin);
Vue.use(VueProgressBar, progressbarConfig);
Vue.config.productionTip = false;

store.dispatch('init');

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
