import PageService from '@/services/page.service';
import Page from '@/store/models/page';

export default {
  namespaced: true,
  state: {
    formPageId: null,
  },
  mutations: {
    formPageId(state, formPageId) {
      state.formPageId = formPageId;
    },
  },
  actions: {
    init({ dispatch }) {
      return dispatch('getPages');
    },
    terminate() {
      return Page.deleteAll();
    },
    async newPage({ commit, getters }) {
      const page = getters.newPage || await Page.createNew();
      commit('formPageId', page.id);
    },
    async discardNewPage({ getters }) {
      if (getters.newPage) {
        getters.newPage.$delete();
      }
    },
    pageProps(store, payload) {
      Page.update({
        where: payload.pageId,
        data: payload.props,
      });
    },
    async getPages({ rootState, state }) {
      const res = await PageService.getPages(rootState.products.productId);
      Page.query()
        .where('id', (page) => page.id !== state.formPageId)
        .delete();
      Page.insert({ data: res });
    },
    async getPage({ rootState }, id) {
      const res = await PageService.getPage(rootState.products.productId, id);
      await Page.insert({ data: res });
    },
    createPage({ rootState }, page) {
      return PageService.createPage(rootState.products.productId, page);
    },
    updatePage({ rootState }, page) {
      return PageService.updatePage(rootState.products.productId, page);
    },
  },
  getters: {
    all() {
      return Page.query()
        .where('$isNew', false)
        .get();
    },
    formPage(state) {
      if (!state.formPageId) {
        return null;
      }
      return Page.query()
        .find(state.formPageId);
    },
    newPage() {
      return Page.query()
        .where('$isNew', true)
        .first();
    },
  },
};
