import HttpService from '@/services/http.service';

class UserService {
  getMe() {
    return HttpService.get('users/me')
      .then((res) => res.data);
  }

  updateMe(user) {
    return HttpService.patch(`users/${user.id}`, user)
      .then((res) => res.data);
  }
}

export default new UserService();
