import HttpService from '@/services/http.service';

class TickerService {
  getTickers(productId, params) {
    return HttpService.get(`products/${productId}/tickers`, {
      params,
    })
      .then((res) => res.data);
  }
}

export default new TickerService();
