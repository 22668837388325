import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import VuexORMisDirtyPlugin from '@vuex-orm/plugin-change-flags';

// Stores
import auth from '@/store/auth';
import liveAlerts from '@/store/live-alerts';
import products from '@/store/products';
import alerts from '@/store/alerts';
import alertChannels from '@/store/alert-channels';
import positions from '@/store/positions';
import navigationDrawer from '@/store/navigation-drawer';
import tickers from '@/store/tickers';
import subscribers from '@/store/subscribers';
import user from '@/store/user';

// Models
import Subscriber from '@/store/models/subscriber';
import Product from '@/store/models/product';
import AlertChannel from '@/store/models/alert-channel';
import Alert from '@/store/models/alert';
import Position from '@/store/models/position';
import AlertChannelStatus from '@/store/models/alert-channel-status';
import Page from '@/store/models/page';
import pages from '@/store/pages';

Vue.use(Vuex);

VuexORM.use(VuexORMisDirtyPlugin, {
  // exposeFlagsExternally: false,
});
const database = new VuexORM.Database();

database.register(Product);
database.register(Subscriber);
database.register(Alert);
database.register(AlertChannel);
database.register(AlertChannelStatus);
database.register(Position);
database.register(Page);

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  actions: {
    init({ dispatch }) {
      dispatch('auth/init');
    },
  },
  modules: {
    auth,
    liveAlerts,
    products,
    subscribers,
    alerts,
    alertChannels,
    positions,
    navigationDrawer,
    tickers,
    user,
    pages,
  },
});
