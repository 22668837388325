import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';

export default class AlertChannel extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'alert_channels';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      type: this.attr(''),
      name: this.attr(''),
      integration: this.attr(''),
      credentials: this.attr(null),
      product_id: this.attr(null),
      is_enabled: this.attr(true),
      updated_at: this.attr(''),
      created_at: this.attr(''),
    };
  }
}
