import HttpService from '@/services/http.service';

class PageService {
  getPages(productId, params) {
    return HttpService.get(`products/${productId}/pages`, {
      params,
    })
      .then((res) => res.data);
  }

  getPage(productId, pageId) {
    return HttpService.get(`products/${productId}/pages/${pageId}`)
      .then((res) => res.data);
  }

  createPage(productId, page) {
    return HttpService.post(`products/${productId}/pages`, page)
      .then((res) => res.data);
  }

  updatePage(productId, page) {
    return HttpService.patch(`products/${productId}/pages/${page.id}`, page)
      .then((res) => res.data);
  }
}

export default new PageService();
