import HttpService from '@/services/http.service';

class PositionService {
  getPositions(productId, params) {
    return HttpService.get(`products/${productId}/positions`, {
      params,
    })
      .then((res) => res.data);
  }

  getPosition(productId, positionId) {
    return HttpService.get(`products/${productId}/positions/${positionId}`)
      .then((res) => res.data);
  }

  createPosition(productId, position) {
    return HttpService.post(`products/${productId}/positions`, position)
      .then((res) => res.data);
  }

  updatePosition(productId, position) {
    return HttpService.patch(`products/${productId}/positions/${position.id}`, position)
      .then((res) => res.data);
  }
}

export default new PositionService();
