import ProductService from '@/services/product.service';
import { uuidv4 } from '@/utils/helpers';
import Alert from '@/store/models/alert';

export default {
  namespaced: true,
  state: {
    socket: null,
    interval: null,
  },
  mutations: {
    socket(state, socket) {
      state.socket = socket;
    },
    interval(state, interval) {
      state.interval = interval;
    },
  },
  actions: {
    async init({ rootState, commit, dispatch }) {
      const res = await ProductService.getExchangeConnection(rootState.products.productId);
      if (!res.connected) {
        return false;
      }
      const config = res.websocket;

      const connectId = uuidv4();
      const socket = new WebSocket(`${config.instanceServers[0].endpoint}?token=${config.token}&[connectId=${connectId}]`);
      commit('socket', socket);

      const interval = setInterval(() => {
        socket.send(JSON.stringify({
          id: uuidv4(),
          type: 'ping',
        }));
      }, config.instanceServers[0].pingInterval);
      commit('interval', interval);

      socket.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'welcome') {
          dispatch('subscribeOrders');
        } else if (message.type === 'message'
          && message.hasOwnProperty('subject') && message.subject === 'orderChange'
          && message.data.type === 'match') {
          dispatch('createAlert', message.data);
        }
      });

      socket.addEventListener('close', (event) => {
        console.log(event);
      });
    },
    terminate({ state, commit }) {
      clearInterval(state.interval);
      if (state.socket) {
        state.socket.close();
      }
      commit('interval', null);
      commit('socket', null);
    },
    subscribeOrders({ state }) {
      state.socket.send(JSON.stringify({
        id: uuidv4(),
        type: 'subscribe',
        topic: '/spotMarket/tradeOrders',
        privateChannel: true,
        response: true,
      }));
    },
    async createAlert({ dispatch }, order) {
      const tickers = order.symbol.split('-');
      const alert = new Alert({
        from_ticker: tickers[0],
        to_ticker: tickers[1],
        side: order.side.toUpperCase(),
        type: order.orderType.toUpperCase(),
        price: order.matchPrice,
        portfolio_percentage: 5,
      });

      await dispatch('alerts/createAlert', alert, { root: true });
      await alert.$save();
    },
  },
  getters: {},
};
