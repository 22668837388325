import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';
import { PositionSide } from '@/store/models/position-side';
import { PositionStatus } from '@/store/models/position-status';

export default class Position extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'positions';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      side: this.attr(PositionSide.BUY),
      status: this.attr(PositionStatus.OPEN),
      portfolio_percentage: this.attr(''),
      open_percentage: this.attr(''),
      from_ticker: this.attr(''),
      to_ticker: this.attr(''),
      average_entry_price: this.attr(''),
      average_exit_price: this.attr(''),
      opened_at: this.attr(''),
      closed_at: this.attr(''),
      stop: this.attr(null),
      target: this.attr(null),
      comment: this.attr(null),
      profit_percentage: this.attr(null),
      product_id: this.attr(''),
      service_id: this.attr(null),
      updated_at: this.attr(''),
      created_at: this.attr(''),
    };
  }
}
