import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';

Vue.use(Router);

const dashboardRoutes = [
  {
    path: 'alerts/:section/:id',
    name: 'alert',
    component: () => import(/* webpackChunkName: "alert" */ '@/views/dashboard/Alerts.vue'),
  },
  {
    path: 'alerts/:section?',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "alerts" */ '@/views/dashboard/Alerts.vue'),
  },
  {
    path: 'positions/:section',
    name: 'positions',
    component: () => import(/* webpackChunkName: "positions" */ '@/views/dashboard/Positions.vue'),
  },
  {
    path: 'subscribers',
    name: 'subscribers',
    component: () => import(/* webpackChunkName: "subscribers" */ '@/views/dashboard/Subscribers.vue'),
  },
  {
    path: 'subscribers/:id',
    name: 'subscriber',
    component: () => import(/* webpackChunkName: "subscriber" */ '@/views/dashboard/Subscribers.vue'),
  },
  {
    path: 'pages',
    name: 'pages',
    component: () => import(/* webpackChunkName: "pages" */ '@/views/dashboard/Pages.vue'),
  },
  {
    path: 'pages/:id',
    name: 'page',
    component: () => import(/* webpackChunkName: "page" */ '@/views/dashboard/Pages.vue'),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/dashboard/Settings.vue'),
  },
  {
    path: 'profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/dashboard/Profile.vue'),
  },
];

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: () => import(/* webpackChunkName: "request-password-reset" */'./views/RequestPasswordReset.vue'),
  },
  {
    path: '/password-reset/:email/:token',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */'./views/PasswordReset.vue'),
  },
  {
    path: '/:productId?',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard.vue'),
    redirect: 'alerts',
    children: dashboardRoutes,
  },
];

class MyRouter extends Router {
  push(location, onComplete, onAbort) {
    if (this.isDashboardRoute(location)) {
      this.addProductIdIfMissing(location);
    }
    return super.push(location, onComplete, onAbort);
  }

  addProductIdIfMissing(location) {
    if (!location.params) {
      location.params = {};
    }
    if (!location.params.hasOwnProperty('productId')) {
      location.params.productId = store.state.products.productId;
    }
  }

  isDashboardRoute(location) {
    return dashboardRoutes.find((route) => route.name === location.name);
  }
}

const router = new MyRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
