import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';
import dayjs from 'dayjs';

export default class Subscriber extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'subscribers';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      email: this.attr(''),
      name: this.attr(''),
      password: this.attr(''),
      phone: this.attr(null),
      phone_dial_code: this.attr(null),
      source: this.attr(null),
      status: this.attr(null),
      source_id: this.attr(null),
      product_id: this.attr(null),
      services: this.attr(null),
      updated_at: this.attr(''),
      created_at: this.attr(dayjs().format()),
    };
  }
}
