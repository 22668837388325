import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';
import AlertChannel from '@/store/models/alert-channel';

export default class Product extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'products';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      type: this.attr(''),
      name: this.attr(''),
      exchange_integration: this.attr(''),
      exchange_credentials: this.attr(''),
      subscribers_webhook_url: this.attr(''),
      alerts_webhook_url: this.attr(''),
      positions_webhook_url: this.attr(''),
      pages_webhook_url: this.attr(''),
      webhook_token: this.attr(''),
      updated_at: this.attr(''),
      created_at: this.attr(''),
      services: this.attr({}),
      alert_channels: this.hasMany(AlertChannel, 'product_id'),
    };
  }
}
