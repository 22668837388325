import HttpService from '@/services/http.service';

class SubscriberService {
  getSubscribers(productId, params) {
    return HttpService.get(`products/${productId}/subscribers`, {
      params,
    })
      .then((res) => res.data);
  }

  getSubscriber(productId, subscriberId) {
    return HttpService.get(`products/${productId}/subscribers/${subscriberId}`)
      .then((res) => res.data);
  }

  createSubscriber(productId, subscriber) {
    return HttpService.post(`products/${productId}/subscribers`, subscriber)
      .then((res) => res.data);
  }

  updateSubscriber(productId, subscriber) {
    return HttpService.patch(`products/${productId}/subscribers/${subscriber.id}`, subscriber)
      .then((res) => res.data);
  }
}

export default new SubscriberService();
