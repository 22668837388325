import HttpService from '@/services/http.service';

class AlertService {
  getAlerts(productId, params) {
    return HttpService.get(`products/${productId}/alerts`, {
      params,
    })
      .then((res) => res.data);
  }

  getAlert(productId, alertId) {
    return HttpService.get(`products/${productId}/alerts/${alertId}`)
      .then((res) => res.data);
  }

  createAlert(productId, alert) {
    return HttpService.post(`products/${productId}/alerts`, alert)
      .then((res) => res.data);
  }

  updateAlert(productId, alert) {
    return HttpService.patch(`products/${productId}/alerts/${alert.id}`, alert)
      .then((res) => res.data);
  }

  confirmAlert(productId, alert) {
    return HttpService.post(`products/${productId}/alerts/${alert.id}/confirm`, alert)
      .then((res) => res.data);
  }

  discardAlert(productId, alert) {
    return HttpService.post(`products/${productId}/alerts/${alert.id}/discard`, alert)
      .then((res) => res.data);
  }
}

export default new AlertService();
