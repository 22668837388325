export function isObject(value) {
  return value !== null && typeof value === 'object';
}

export function getObjectProperty(objectRef, propertyName) {
  let value;
  let valueRef = objectRef;
  const propNames = propertyName.split('.');

  for (let i = 0; i < propNames.length; i++) {
    const key = propNames[i];
    value = valueRef[key];

    if (isObject(value)) {
      valueRef = valueRef[key];
    } else {
      break;
    }
  }

  return value;
}
