import Vue from 'vue';
import VueAuthenticate from 'vue-authenticate';
import app from '@/main';
import config from '@/config/auth.config';
import HttpService from '@/services/http.service';
import { getObjectProperty } from '@/utils/auth';

// Required for vue-auth to hook into our http service
// To attach token to requests etc
Vue.prototype.$http = HttpService;

const instance = VueAuthenticate.factory(Vue.prototype.$http, config);

instance.setToken = function setToken(response, tokenPath) {
  if (response[this.options.responseDataKey]) {
    response = response[this.options.responseDataKey];
  }

  const responseTokenPath = tokenPath || this.options.tokenPath;
  const token = getObjectProperty(response, responseTokenPath);

  if (token) {
    this.storage.setItem(this.tokenName, token);
    app.$store.dispatch('auth/authSuccess', {
      isAuthenticated: this.isAuthenticated(),
      data: response,
    });
  }
};

export default instance;
