import { Model } from '@vuex-orm/core';
import { uuidv4 } from '@/utils/helpers';
import AlertChannel from '@/store/models/alert-channel';
import Alert from '@/store/models/alert';

export default class AlertChannelStatus extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'alert_channel_statuses';

  static fields() {
    return {
      id: this.attr(() => uuidv4()),
      status: this.attr(''),
      request: this.attr(null),
      response_code: this.attr(''),
      response_content: this.attr(''),
      channel_id: this.attr(null),
      channel: this.belongsTo(AlertChannel, 'channel_id'),
      alert_id: this.attr(null),
      alert: this.belongsTo(Alert, 'alert_id'),
      updated_at: this.attr(''),
      created_at: this.attr(''),
    };
  }
}
