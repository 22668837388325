import TickerService from '@/services/ticker.service';

export default {
  namespaced: true,
  state: {
    tickers: [],
    loading: false,
  },
  mutations: {
    tickers(state, tickers) {
      state.tickers = tickers;
    },
    loading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    init() {
    },
    terminate({ commit }) {
      return commit('tickers', []);
    },
    async getTickers({ rootState, commit }, query) {
      commit('loading', true);
      const res = await TickerService.getTickers(rootState.products.productId, query);
      commit('tickers', res);
      commit('loading', false);
    },
  },
  getters: {
    all(state) {
      return Object.values(state.tickers);
    },
  },
};
